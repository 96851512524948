import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/* <!-- Start Footer Area --> */}
      <footer className="footer-area area-padding-top background_one">
        <div className="container">
          <div
            className="row flex flex-wrap justify-center items-center h-screen"
            // style={{ textAlign: "center" }}
          >
            {/* Our Services */}
            <div className="col-lg-3 col-md-6 col-sm-12 mb-5 single-footer-widget">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <Link to="/services">Dental Implant</Link>
                </li>
                <li>
                  <Link to="/services">Aliner</Link>
                </li>
                <li>
                  <Link to="/services">Braces</Link>
                </li>
                <li>
                  <Link to="/services">Teeth Whitening</Link>
                </li>
               
                <li>
                  <Link to="/services">Smile Designing</Link>
                </li>
              </ul>
            </div>

            {/* Quick Links */}
            <div className="col-lg-3 col-md-6 col-sm-12 mb-5 single-footer-widget">
              <h4>Quick Links</h4>
              <ul>
                <li>
                  <Link to="/services">Our Services</Link>
                </li>
                <li>
                  <Link to="/testimonials">Testimonials</Link>
                </li>
                <li>
                  <Link to="/contact">Contact us</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms-of-service">Terms of Service</Link>
                </li>
                
               
              </ul>
            </div>

            {/* Contact Us */}
            <div className="col-lg-3 col-md-6 col-sm-12 mb-5 single-footer-widget">
              <h4>Contact Us</h4>
              <ul>
                <li>
                  <a href="tel:+919096569324">
                    <i className="fa fa-phone"></i> +91 90965 69324
                  </a>
                </li>
                <li>
                  <a href="mailto:contact@drshukladentalclinic.com">
                    <i className="fa fa-envelope"></i>{" "}
                    contact@drshukladentalclinic.com
                  </a>
                </li>
                <li>
                  <a
                    href="https://g.co/kgs/ZU6dC9W"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-map-marker"></i> New Shree Jee Complex, Beside Manohar Muncipal School, Gondia, Maharashtra 441601
                  </a>
                </li>
              </ul>
            </div>

            {/* Google Map and Newsletter */}
            <div className="col-lg-3 col-md-6 col-sm-12 single-footer-widget">
            <h4>Our Location</h4>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3713.2083414461854!2d80.19360557600636!3d21.460340372814056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a2baea9d50b20c1%3A0x2b873dfb3d0d9721!2sShukla%20mulitspeciality%20dental%20clinic%20and%20implant%20centre(BEST%20DENTAL%20CLINIC%20IN%20GONDIA)!5e0!3m2!1sen!2sin!4v1731059316451!5m2!1sen!2sin"
                width="100%"
                height="150"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Maps Embed"
              ></iframe>

            
            </div>
          </div>

          {/* Footer Bottom */}
          <div className="row footer-bottom d-flex justify-content-between align-items-center">
            <p className="col-lg-6 col-sm-12 footer-text text-center text-lg-left">
              &copy;  Design & Developed by <a href="https://www.kodekalp.com" >KodeKalp Global Technologies.</a> All Rights Reserved.
            </p>
            <div className="col-lg-6 col-sm-12 footer-social text-center text-lg-right">
              <a
                href="https://www.facebook.com/drRshukla"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              {/* <a
                href="https:twitter.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter"></i>
              </a> */}
              <a
                href="https://www.instagram.com/shukladrrahul/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
              {/* <a
                href="https://linkedin.com/company/drshukladentalclinic"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin"></i>
              </a> */}
              {/* <a
                href="https://youtube.com/drshukladentalclinic"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-youtube"></i>
              </a> */}
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- End Footer Area --> */}
    </>
  );
};

export default Footer;
