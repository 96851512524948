// PreventiveCare.js
import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './PreventiveCare.css'; // Import custom CSS
import TestimonialsMsg from '../components/TestimonialsMsg';

const PreventiveCare = () => {
  const preventiveServices = [
    {
      title: 'Routine Checkups',
      description:
        'Regular dental examinations to monitor your oral health and detect any potential issues early.',
      icon: 'flaticon-stethoscope', // Replace with relevant icon class or image
    },
    {
      title: 'Professional Cleanings',
      description:
        'Thorough cleaning by our dental hygienists to remove plaque and tartar, preventing cavities and gum disease.',
      icon: 'flaticon-toothbrush', // Replace with relevant icon class or image
    },
    {
      title: 'Dental Sealants',
      description:
        'Protective coatings applied to the chewing surfaces of back teeth to prevent decay.',
      icon: 'flaticon-shield', // Replace with relevant icon class or image
    },
    {
      title: 'Fluoride Treatments',
      description:
        'Application of fluoride to strengthen tooth enamel and prevent tooth decay.',
      icon: 'flaticon-fluoride', // Replace with relevant icon class or image
    },
    {
      title: 'Oral Cancer Screenings',
      description:
        'Early detection of oral cancer through comprehensive screenings during your dental visits.',
      icon: 'flaticon-cancer', // Replace with relevant icon class or image
    },
    {
      title: 'Patient Education',
      description:
        'Guidance on proper oral hygiene practices and lifestyle choices to maintain optimal oral health.',
      icon: 'flaticon-book', // Replace with relevant icon class or image
    },
  ];

  return (<>
   {/* <!--================Home Banner Area =================--> */}
   <section className="banner_area">
        <div className="banner_inner d-flex align-items-center">
          <div className="container">
            <div
              className="banner_content d-md-flex justify-content-between align-items-center"
            >
              <div className="mb-3 mb-md-0">
                <h2>Preventive Dental Care</h2>
                <p>                At Dr. Shukla Dental Clinic, our preventive care services are designed to maintain your oral health, prevent dental issues, and ensure a lifetime of healthy smiles.
                </p>
              </div>
              {/* <div className="page_link">
                <Link to="/">Home</Link>
                <Link to="/preventive-care">Preventive Dental Care</Link>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!--================End Home Banner Area =================--> */}
 
    <section className="preventive-care-area area-padding-top">
        
      <Container>
        {/* Services Section */}
        <Row className="mt-5">
          {preventiveServices.map((service, index) => (
            <Col key={index} md={6} lg={4} className="mb-4">
              <Card className="preventive-service-card h-100 text-center p-4">
                <div className="service-icon mb-3">
                  <i className={service.icon}></i>
                </div>
                <Card.Body>
                  <Card.Title>{service.title}</Card.Title>
                  <Card.Text>{service.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Benefits Section */}
        <Row className="mt-5">
          <Col lg={6}>
            <div className="benefits-image">
              <img
                src="/images/preventive-care.jpg" // Replace with your image path
                alt="Preventive Care"
                className="img-fluid rounded"
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="benefits-content">
              <h3>Benefits of Preventive Care</h3>
              <ul className="benefits-list">
                <li>Early detection and treatment of dental issues.</li>
                <li>Prevention of cavities and gum disease.</li>
                <li>Maintenance of overall oral health.</li>
                <li>Cost-effective in the long run by avoiding extensive treatments.</li>
                <li>Improved confidence with a healthy smile.</li>
              </ul>
              <Link to="/contact" className="learn-more btn btn-primary mt-3">
                Schedule an Appointment
              </Link>
            </div>
          </Col>
        </Row>

        {/* Optional: Testimonials Related to Preventive Care */}
        <Row className="mt-5">
          <Col lg={12}>
            <div className="related-testimonials">
              <h3 className="text-center mb-4">Patient Testimonials</h3>
              <TestimonialsMsg/>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    </>
  );
};

export default PreventiveCare;
