import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  // State to manage the navbar collapse
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  // Function to handle the toggle button click
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  // Function to close the menu when a menu item is clicked
  const closeMenu = () => setIsNavCollapsed(true);

  return (
    <>
      {/* <!--================Header Menu Area =================--> */}
      <header className="header_area">
        <div className="top_menu row m0">
          <div className="container">
            <div className="float-left">
              {/* Updated Email and Location */}
               <a className="dn_btn" href="mailto:contact@shukladentalclinic.com">
                 <i className="ti-email"></i>contact@shukladentalclinic.com
               </a>
               <span className="dn_btn">
               <a className="dn_btn" href="https://g.co/kgs/ZU6dC9W">
                 <i className="ti-location-pin"></i>New Shree Jee Complex, Gondia
                 </a>
               </span>
            </div>
            <div className="float-right">
              <ul className="list header_social">
                <li>
                  <a
                    href="https://www.facebook.com/drRshukla"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="ti-facebook"></i>
                  </a>
                </li>
                {/* <li>
                  <a
                    href="https://twitter.com/drshukladentalclinic"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="ti-twitter-alt"></i>
                  </a>
                </li> */}
                {/* <li>
                  <a
                    href="https://linkedin.com/company/drshukladentalclinic"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="ti-linkedin"></i>
                  </a>
                </li> */}
                <li>
                  <a
                    href="https://www.instagram.com/shukladrrahul/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="ti-instagram"></i>
                  </a>
                </li>
                {/* <li>
                  <a
                    href="https://youtube.com/gondiadentalclinic"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="ti-youtube"></i>
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="main_menu">
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container">
              {/* <!-- Brand and toggle get grouped for better mobile display --> */}
              <Link className="navbar-brand logo_h" to="/">
                <img src="img/shukla-dental-clinic-gondia.png" alt="Dr. Shukla Dental Clinic Logo" height={50} />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                aria-controls="navbarSupportedContent"
                aria-expanded={!isNavCollapsed}
                aria-label="Toggle navigation"
                onClick={handleNavCollapse}
              >
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
              <div
                className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse offset`}
                id="navbarSupportedContent"
              >
                <ul className="nav navbar-nav menu_nav ml-auto">
                  <li className="nav-item">
                    <Link className="nav-link" to="/" onClick={closeMenu}>
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/services" onClick={closeMenu}>
                      Services
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link className="nav-link" to="/our-dentists" onClick={closeMenu}>
                      Our Dentists
                    </Link>
                  </li> */}
                  <li className="nav-item">
                    <Link className="nav-link" to="/testimonials" onClick={closeMenu}>
                      Testimonial
                    </Link>
                  </li>
                  {/* Uncomment and update if needed
                  <li className="nav-item submenu dropdown">
                    <a
                      href="#"
                      className="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Blog
                    </a>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link className="nav-link" to="/blog" onClick={closeMenu}>
                          Blog
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/blog-details" onClick={closeMenu}>
                          Blog Details
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/dental-tips" onClick={closeMenu}>
                          Dental Tips
                        </Link>
                      </li>
                    </ul>
                  </li>
                  */}
                  <li className="nav-item">
                    <Link className="nav-link" to="/about-us" onClick={closeMenu}>
                      About
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/contact" onClick={closeMenu}>
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
      {/* <!--================Header Menu Area =================--> */}
    </>
  );
};

export default Header;
