import React from "react";
import { Link } from "react-router-dom";
import doctor from "../assets/doctor.png";
import {
  FaUserMd,
  FaSmile,
  FaCalendarCheck,
  FaAward,
  FaProcedures,
  FaGraduationCap,
  FaCertificate,
  FaTeeth,
  FaTooth,
  FaSmileBeam,
  FaTeethOpen,
  FaBaby,
  FaBone,
  FaMicroscope,
  FaStarOfLife,
  FaHeart,
} from "react-icons/fa";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./Service.css";

const Services = () => {
  return (
    <>
      {/* <!--================Home Banner Area =================--> */}
      <section className="banner_area">
        <div className="banner_inner d-flex align-items-center">
          <div className="container">
            <div className="banner_content d-md-flex justify-content-between align-items-center">
              <div className="mb-3 mb-md-0">
                <h2>Our Services</h2>
                <p>Comprehensive Dental Care for a Healthy Smile</p>
              </div>
              <div className="page_link">
                <Link to="/">Home</Link>
                <Link to="/services"> Services</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--================End Home Banner Area =================--> */}

      {/* <!--================Service Area Start =================--> */}

      <section className="service-area area-padding-top">
        <div className="container">
          <div className="row">
            {/* Card 1: Implant Centre */}
            <div className="col-md-6 col-lg-4 mb-4">
              <div
                className="card single-service text-center h-100"
                style={{
                  border: "1px solid black", // Black border added here
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "15px",
                }}
              >
                <img
                  src="/img/Services/implant-drshukladentalclinic-gondia.jpg"
                  alt="Implant Centre"
                  style={{
                    borderRadius: "15px",
                    width: "100%",
                    objectFit: "cover",
                    aspectRatio: "1 / 1",
                  }}
                />
                <div className="card-body" style={{ padding: "20px" }}>
                  <h4
                    className="card-title"
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}
                  >
                    Dental Implant
                  </h4>
                  <p
                    className="card-text"
                    style={{ fontSize: "14px", color: "#666" }}
                  >
                    State-of-the-art dental implants for missing teeth
                    restoration. Durable, natural-looking, and long-lasting
                    solutions.
                  </p>
                </div>
              </div>
            </div>

            {/* Card 2: Aligner Clinic */}
            <div className="col-md-6 col-lg-4 mb-4">
              <div
                className="card single-service text-center h-100"
                style={{
                  border: "1px solid black",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "15px",
                }}
              >
                <img
                  src="/img/Services/implant-drshukladentalclinic-gondia-2.jpg"
                  alt="Aligner Clinic"
                  style={{
                    borderRadius: "15px",
                    width: "100%",
                    objectFit: "cover",
                    aspectRatio: "1 / 1",
                  }}
                />
                <div className="card-body" style={{ padding: "20px" }}>
                  <h4
                    className="card-title"
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}
                  >
                    Aligner
                  </h4>
                  <p
                    className="card-text"
                    style={{ fontSize: "14px", color: "#666" }}
                  >
                    Invisible aligners for straightening your teeth. Get the
                    smile you've always dreamed of without traditional braces.
                  </p>
                </div>
              </div>
            </div>

            {/* Card 3: Braces Clinic */}
            <div className="col-md-6 col-lg-4 mb-4">
              <div
                className="card single-service text-center h-100"
                style={{
                  border: "1px solid black",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "15px",
                }}
              >
                <img
                  src="/img/Services/aligner-drshukladentalclinic-gondia.jpg"
                  alt="Braces Clinic"
                  style={{
                    borderRadius: "15px",
                    width: "100%",
                    objectFit: "cover",
                    aspectRatio: "1 / 1",
                  }}
                />
                <div className="card-body" style={{ padding: "20px" }}>
                  <h4
                    className="card-title"
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}
                  >
                    Braces
                  </h4>
                  <p
                    className="card-text"
                    style={{ fontSize: "14px", color: "#666" }}
                  >
                    Comprehensive orthodontic treatment with braces for
                    children, teens, and adults. A step toward a confident
                    smile.
                  </p>
                </div>
              </div>
            </div>

            {/* Card 4: Teeth Whitening */}
            <div className="col-md-6 col-lg-4 mb-4">
              <div
                className="card single-service text-center h-100"
                style={{
                  border: "1px solid black",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "15px",
                }}
              >
                <img
                  src="/img/Services/teeth-whitening-in-gondia.jpg"
                  alt="Teeth Whitening"
                  style={{
                    borderRadius: "15px",
                    width: "100%",
                    objectFit: "cover",
                    aspectRatio: "1 / 1",
                  }}
                />
                <div className="card-body" style={{ padding: "20px" }}>
                  <h4
                    className="card-title"
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}
                  >
                    Teeth Whitening
                  </h4>
                  <p
                    className="card-text"
                    style={{ fontSize: "14px", color: "#666" }}
                  >
                    Professional teeth whitening services to brighten your smile
                    and enhance your confidence.
                  </p>
                </div>
              </div>
            </div>

            {/* Card 5: Root Canal Treatment */}
            <div className="col-md-6 col-lg-4 mb-4">
              <div
                className="card single-service text-center h-100"
                style={{
                  border: "1px solid black",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "15px",
                }}
              >
                <img
                  src="/img/Services/Root-Canal-Treatment-drshukladentalclinic-in-gondia.jpg"
                  alt="Root Canal Treatment"
                  style={{
                    borderRadius: "15px",
                    width: "100%",
                    objectFit: "cover",
                    aspectRatio: "1 / 1",
                  }}
                />
                <div className="card-body" style={{ padding: "20px" }}>
                  <h4
                    className="card-title"
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}
                  >
                    Root Canal Treatment
                  </h4>
                  <p
                    className="card-text"
                    style={{ fontSize: "14px", color: "#666" }}
                  >
                    Painless root canal procedures to save your natural teeth
                    and restore oral health.
                  </p>
                </div>
              </div>
            </div>

            {/* Card 6: Smile Designing*/}
            <div className="col-md-6 col-lg-4 mb-4">
              <div
                className="card single-service text-center h-100"
                style={{
                  border: "1px solid black",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "15px",
                }}
              >
                <img
                  src="/img/Services/Smile-Designing-Root-Canal-Treatment-drshukladentalclinic-in-gondia.jpg"
                  alt="Smile Designing"
                  style={{
                    borderRadius: "15px",
                    width: "100%",
                    objectFit: "cover",
                    aspectRatio: "1 / 1",
                  }}
                />
                <div className="card-body" style={{ padding: "20px" }}>
                  <h4
                    className="card-title"
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}
                  >
                    Smile Designing
                  </h4>
                  <p
                    className="card-text"
                    style={{ fontSize: "14px", color: "#666" }}
                  >
                    Specialized dental care for children with a gentle, caring
                    approach.
                  </p>
                </div>
              </div>
            </div>

            {/* Card 7: Cosmetic Dentistry */}
            <div className="col-md-6 col-lg-4 mb-4">
              <div
                className="card single-service text-center h-100"
                style={{
                  border: "1px solid black",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "15px",
                }}
              >
                <img
                  src="/img/Services/cosmetic-bonding-Smile-Designing-Root-Canal-Treatment-drshukladentalclinic-in-gondia.jpg"
                  alt="Cosmetic Dentistry"
                  style={{
                    borderRadius: "15px",
                    width: "100%",
                    objectFit: "cover",
                    aspectRatio: "1 / 1",
                  }}
                />
                <div className="card-body" style={{ padding: "20px" }}>
                  <h4
                    className="card-title"
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}
                  >
                    Cosmetic Dentistry
                  </h4>
                  <p
                    className="card-text"
                    style={{ fontSize: "14px", color: "#666" }}
                  >
                    Smile makeovers with veneers, crowns, and other aesthetic
                    dental solutions.
                  </p>
                </div>
              </div>
            </div>

            {/* Card 8: Oral Surgery */}
            <div className="col-md-6 col-lg-4 mb-4">
              <div
                className="card single-service text-center h-100"
                style={{
                  border: "1px solid black",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "15px",
                }}
              >
                <img
                  src="/img/Services/Tooth-Extraction-cosmetic-bonding-Smile-Designing-Root-Canal-Treatment-drshukladentalclinic-in-gondia.jpg"
                  alt="Oral Surgery"
                  style={{
                    borderRadius: "15px",
                    width: "100%",
                    objectFit: "cover",
                    aspectRatio: "1 / 1",
                  }}
                />
                <div className="card-body" style={{ padding: "20px" }}>
                  <h4
                    className="card-title"
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}
                  >
                    Oral Surgery
                  </h4>
                  <p
                    className="card-text"
                    style={{ fontSize: "14px", color: "#666" }}
                  >
                    Surgical solutions for complex dental and oral conditions.
                  </p>
                </div>
              </div>
            </div>

            {/* Card 9: Preventive Care */}
            <div className="col-md-6 col-lg-4 mb-4">
              <div
                className="card single-service text-center h-100"
                style={{
                  border: "1px solid black",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "15px",
                }}
              >
                <img
                  src="/img/Services/Preventative-Dental-Care-drshukladental-clinic-in-gondia.jpg"
                  alt="Preventive Care"
                  style={{
                    borderRadius: "15px",
                    width: "100%",
                    objectFit: "cover",
                    aspectRatio: "1 / 1",
                  }}
                />
                <div className="card-body" style={{ padding: "20px" }}>
                  <h4
                    className="card-title"
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}
                  >
                    Preventive Care
                  </h4>
                  <p
                    className="card-text"
                    style={{ fontSize: "14px", color: "#666" }}
                  >
                    Regular check-ups and cleanings to maintain optimal oral
                    health.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="service-area">
        <Container>
        <Row className="mt-5">
          {[
            {
              icon: <FaTeeth className="text-primary" />,
              title: "Immediate Teeth After Implantation",
              description:
                "Most clinics require you to wait 3 to 6 months to get teeth fitted. At Dr. Shukla Dental Clinic.",
            },
            {
              icon: <FaTooth className="text-primary" />,
              title: "Occlusion Correction",
              description:
                "Treatment for misalignment due to missing teeth, preventing related issues like acidity, neck, and back pain.",
            },
            {
              icon: <FaSmileBeam className="text-primary" />,
              title: "Vigil Smile",
              description:
                "Achieve a perfect smile with our dental aesthetics treatments.",
            },
            {
              icon: <FaTeethOpen className="text-primary" />,
              title: "Fix Batiti",
              description:
                "Straighten crooked teeth and improve your bite alignment.",
            },
            {
              icon: <FaBaby className="text-primary" />,
              title: "Children's Teeth Care",
              description:
                "Comprehensive dental care for children to ensure healthy growth and development.",
            },
            {
              icon: <FaBone className="text-primary" />,
              title: "Broken Jaw Treatment",
              description:
                "Specialized care for jaw fractures to restore proper function.",
            },
            {
              icon: <FaMicroscope className="text-primary" />,
              title: "Kinema Iti Technique",
              description:
                "Advanced dental techniques for precise diagnosis and treatment.",
            },
            {
              icon: <FaStarOfLife className="text-primary" />,
              title: "Scar Revision",
              description:
                "Scar revision surgery to improve the appearance of scars.",
            },
            {
              icon: <FaHeart className="text-primary" />,
              title: "Dental Valery",
              description:
                "Unique techniques for long-lasting dental care results.",
            },
          ].map((service, index) => (
            <Col key={index} md={6} lg={4} className="mb-4">
              <Card className="service-card h-100 text-center p-3 border-0 shadow-sm hover-effect">
                <Card.Body>
                  <div className="mb-3" style={{ fontSize: "2rem" }}>
                    {service.icon}
                  </div>
                  <h3 className="mb-3">{service.title}</h3>
                  <p>{service.description}</p>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section> */}

      {/* <!--================Service Area End =================--> */}
      {/* <!--================Service Area End =================--> */}

      {/* <!--================ Appointment Area Start =================--> */}
      <section className="appointment-area area-padding-top">
        <div className="container">
          <div className="appointment-inner">
            <div className="row">
              <div className="col-sm-12 col-lg-5 offset-lg-1">
                <h3>Have Some Questions?</h3>
                <div className="accordion" id="accordionExample">
                  {/* FAQ 1 */}
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          What services do you offer?
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        We offer a wide range of dental services including
                        preventive care, restorative treatments, cosmetic
                        dentistry, orthodontics, and emergency dental care to
                        ensure your oral health is maintained at its best.
                      </div>
                    </div>
                  </div>
                  {/* FAQ 2 */}
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          How can I schedule an appointment?
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        You can easily schedule an appointment by clicking the
                        "Book an Appointment" button on our website, calling our
                        office directly, or visiting us in person during our
                        working hours.
                      </div>
                    </div>
                  </div>
                  {/* FAQ 3 */}
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Do you accept insurance?
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseThree"
                      className="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        Yes, we accept a variety of dental insurance plans.
                        Please contact our office to verify if your insurance is
                        accepted and to understand your coverage details.
                      </div>
                    </div>
                  </div>
                  {/* FAQ 4 */}
                  <div className="card">
                    <div className="card-header" id="headingFour">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          What are your office hours?
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseFour"
                      className="collapse"
                      aria-labelledby="headingFour"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        Our office is open Monday through Friday from 9:00 AM to
                        7:00 PM, and Saturdays from 9:00 AM to 1:00 PM. We are
                        closed on Sundays.
                      </div>
                    </div>
                  </div>
                  {/* FAQ 5 */}
                  <div className="card">
                    <div className="card-header" id="headingFive">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          How do I prepare for my dental appointment?
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseFive"
                      className="collapse"
                      aria-labelledby="headingFive"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        Please arrive a few minutes early for your appointment,
                        bring any necessary dental records or insurance
                        information, and inform us of any medications you are
                        currently taking or any dental concerns you may have.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Appointment Form */}
              <div className="col-lg-5">
                <div className="appointment-form">
                  <h3>Make an Appointment</h3>
                  <form
                    className="form-contact contact_form"
                    action="contact_process.php"
                    method="post"
                    id="contactForm"
                    noValidate="novalidate"
                  >
                    <div className="form-group">
                      <label htmlFor="name">Full Name</label>
                      <input
                        className="form-control"
                        name="name"
                        id="name"
                        type="text"
                        placeholder="Your Name"
                        onFocus={(e) => (e.target.placeholder = "")}
                        onBlur={(e) => (e.target.placeholder = "Your Name")}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        className="form-control"
                        name="email"
                        id="email"
                        type="email"
                        placeholder="Your Email Address"
                        onFocus={(e) => (e.target.placeholder = "")}
                        onBlur={(e) =>
                          (e.target.placeholder = "Your Email Address")
                        }
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="subject">Subject</label>
                      <input
                        className="form-control"
                        name="subject"
                        id="subject"
                        type="text"
                        placeholder="Subject"
                        onFocus={(e) => (e.target.placeholder = "")}
                        onBlur={(e) => (e.target.placeholder = "Subject")}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="message">Message</label>
                      <textarea
                        className="form-control w-100"
                        name="message"
                        id="message"
                        cols="30"
                        rows="9"
                        placeholder="Enter your message"
                        onFocus={(e) => (e.target.placeholder = "")}
                        onBlur={(e) =>
                          (e.target.placeholder = "Enter your message")
                        }
                        required
                      ></textarea>
                    </div>
                    <div className="form-group mt-3">
                      <button
                        type="submit"
                        className="button button-contactForm"
                      >
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ================ contact section end ================= --> */}

      {/* <!--================ Start Brands Area =================--> */}

      {/* <!--================ End Brands Area =================--> */}
    </>
  );
};

export default Services;

// import React from 'react';

// const Services = () => {
//   return (
//     <div className="py-12 bg-gray-50">
//       <div className="container mx-auto px-6">
//         <h1 className="text-4xl font-semibold text-center text-gray-900 mb-12">Our Services</h1>

//         {/* Our Specialties Section */}
//         <section className="mb-16">
//           <h2 className="text-2xl font-semibold text-gray-800 mb-6">Our Specialties</h2>
//           <ul className="space-y-6">
//             <li className="flex items-start space-x-3">
//               <div className="flex-shrink-0">
//                 <span className="text-xl text-blue-500">1.</span>
//               </div>
//               <div className="text-lg text-gray-700">
//                 <strong>Immediate Teeth After Implantation:</strong> After implantation, most clinics require you to wait for 3 to 6 months to get your teeth fitted. At Dr. Shukla Dental Clinic, we offer immediate teeth placement after implantation, so you don't have to wait.
//               </div>
//             </li>
//             <li className="flex items-start space-x-3">
//               <div className="flex-shrink-0">
//                 <span className="text-xl text-blue-500">2.</span>
//               </div>
//               <div className="text-lg text-gray-700">
//                 <strong>Occlusion Correction:</strong> If two or more teeth are missing from the upper or lower jaw, the occlusion (the way the upper and lower teeth meet) can be disturbed. This misalignment can lead to problems such as excessive acidity, neck and back pain. If you experience these symptoms, contact us immediately.
//               </div>
//             </li>
//             <li className="flex items-start space-x-3">
//               <div className="flex-shrink-0">
//                 <span className="text-xl text-blue-500">3.</span>
//               </div>
//               <div className="text-lg text-gray-700">
//                 <strong>Vigil Smile:</strong> Achieve a perfect smile with our specialized treatments.
//               </div>
//             </li>
//             <li className="flex items-start space-x-3">
//               <div className="flex-shrink-0">
//                 <span className="text-xl text-blue-500">4.</span>
//               </div>
//               <div className="text-lg text-gray-700">
//                 <strong>Fix Batiti:</strong> Treatment for straightening crooked teeth and improving your bite.
//               </div>
//             </li>
//             <li className="flex items-start space-x-3">
//               <div className="flex-shrink-0">
//                 <span className="text-xl text-blue-500">5.</span>
//               </div>
//               <div className="text-lg text-gray-700">
//                 <strong>Treatment for Small Children's Teeth:</strong> We provide comprehensive dental care for children, ensuring their teeth grow and develop properly.
//               </div>
//             </li>
//             <li className="flex items-start space-x-3">
//               <div className="flex-shrink-0">
//                 <span className="text-xl text-blue-500">6.</span>
//               </div>
//               <div className="text-lg text-gray-700">
//                 <strong>Broken Jaw Treatment:</strong> We offer specialized care and treatment for jaw fractures and other jaw-related injuries.
//               </div>
//             </li>
//             <li className="flex items-start space-x-3">
//               <div className="flex-shrink-0">
//                 <span className="text-xl text-blue-500">7.</span>
//               </div>
//               <div className="text-lg text-gray-700">
//                 <strong>Kinema Iti Technique:</strong> Advanced dental techniques for precise diagnosis and treatment.
//               </div>
//             </li>
//             <li className="flex items-start space-x-3">
//               <div className="flex-shrink-0">
//                 <span className="text-xl text-blue-500">8.</span>
//               </div>
//               <div className="text-lg text-gray-700">
//                 <strong>Scar Revision:</strong> We provide scar revision surgery to improve the appearance of scars, especially after dental procedures.
//               </div>
//             </li>
//             <li className="flex items-start space-x-3">
//               <div className="flex-shrink-0">
//                 <span className="text-xl text-blue-500">9.</span>
//               </div>
//               <div className="text-lg text-gray-700">
//                 <strong>Dental Valery:</strong> Unique techniques for maintaining the health and beauty of your teeth.
//               </div>
//             </li>
//           </ul>
//         </section>

//         {/* Other Facilities Section */}
//         <section className="mb-16">
//           <h2 className="text-2xl font-semibold text-gray-800 mb-6">Other Facilities Available</h2>
//           <ul className="space-y-6">
//             <li className="text-lg text-gray-700">
//               <strong>Laser Mouth Opening Treatment:</strong> For treating mouth conditions with advanced laser technology.
//             </li>
//             <li className="text-lg text-gray-700">
//               <strong>Dimple Creation:</strong> Enhance your smile with safe and effective dimple creation procedures.
//             </li>
//             <li className="text-lg text-gray-700">
//               <strong>Laser Mole Removal Treatment:</strong> Safe, non-invasive mole removal using laser technology.
//             </li>
//             <li className="text-lg text-gray-700">
//               <strong>Digital Dental X-Ray:</strong> State-of-the-art digital X-rays for precise diagnosis.
//             </li>
//             <li className="text-lg text-gray-700">
//               <strong>Oral Cancer Check and Treatment:</strong> Early detection and treatment of oral cancer to ensure your health and safety.
//             </li>
//             <li className="text-lg text-gray-700">
//               <strong>Battisi (Toothbrushing):</strong> Expert cleaning and care for your teeth to prevent damage and decay.
//             </li>
//             <li className="text-lg text-gray-700">
//               <strong>Composite Filling:</strong> Restorative filling material that looks and feels natural.
//             </li>
//             <li className="text-lg text-gray-700">
//               <strong>Teeth Cleaning by Ultrasonic Machine:</strong> Advanced cleaning techniques to maintain healthy teeth and gums.
//             </li>
//             <li className="text-lg text-gray-700">
//               <strong>Fixed Dentures:</strong> Durable and comfortable dentures that restore your smile and function.
//             </li>
//           </ul>
//         </section>

//         {/* Appointment Section */}
//         <section className="text-center">
//           <h2 className="text-2xl font-semibold text-gray-800 mb-4">For Appointment</h2>
//           <p className="text-lg text-gray-700 mb-4">Call us today to schedule your appointment:</p>
//           <ul className="space-y-2 text-lg text-blue-600">
//             <li>8263927680</li>
//             <li>8806304125</li>
//             <li>9096569324</li>
//           </ul>
//         </section>
//       </div>
//     </div>
//   );
// };

// export default Services;
