import React, { useState, useEffect } from 'react';

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const buttonStyles = {
    position: 'fixed',
    zIndex : 1000,
    bottom: '16px',
    right: '16px',
    padding: '12px',
    backgroundColor: '#3b82f6',
    color: 'white',
    borderRadius: '9999px',
    boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease, opacity 0.3s ease',
    transform: isVisible ? 'scale(1)' : 'scale(0)',
    opacity: isVisible ? 1 : 0,
    animation: isVisible ? 'float 3s ease-in-out infinite' : 'none',
  };

  const floatKeyframes = `@keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }`;

  return (
    <>
      <style>{floatKeyframes}</style>
      <button
        onClick={scrollToTop}
        style={buttonStyles}
        aria-label="Back to Top"
      >
        ↑
      </button>
    </>
  );
};

export default BackToTopButton;
