// Orthodontics.js
import React from 'react'; 
import { Container, Row, Col, Card } from 'react-bootstrap'; 
import { Link } from 'react-router-dom';
import './PreventiveCare.css'; // Import custom CSS

const Orthodontics = () => {
  const orthodonticServices = [
    {
      title: 'Traditional Braces',
      description:
        'Effective metal braces that correct alignment and bite issues for patients of all ages.',
      icon: 'flaticon-braces', // Replace with relevant icon class or image
    },
    {
      title: 'Clear Aligners',
      description:
        'Discreet orthodontic treatment using clear, removable aligners to straighten teeth.',
      icon: 'flaticon-aligners', // Replace with relevant icon class or image
    },
    {
      title: 'Retainers',
      description:
        'Custom-made appliances to maintain teeth alignment post-treatment.',
      icon: 'flaticon-retainer', // Replace with relevant icon class or image
    },
    {
      title: 'Early Orthodontic Treatment',
      description:
        'Intervention for children to guide proper jaw growth and prevent future issues.',
      icon: 'flaticon-pediatric', // Replace with relevant icon class or image
    },
    {
      title: 'Orthodontic Consultations',
      description:
        'Comprehensive assessments to determine the best treatment plan for your needs.',
      icon: 'flaticon-consultation', // Replace with relevant icon class or image
    },
    {
      title: 'Surgical Orthodontics',
      description:
        'Correct severe orthodontic problems with a combination of orthodontic treatment and oral surgery.',
      icon: 'flaticon-surgery', // Replace with relevant icon class or image
    },
  ];

  return (<>
     <section className="banner_area">
        <div className="banner_inner d-flex align-items-center">
          <div className="container">
            <div
              className="banner_content d-md-flex justify-content-between align-items-center"
            >
              <div className="mb-3 mb-md-0">
                <h2>Orthodontics</h2>
              <p>
                At Dr. Shukla Dental Clinic, our orthodontic services are dedicated to aligning your teeth and jaws for a healthier, more attractive smile.
              </p>
              </div>
              {/* <div className="page_link">
                <Link to="/">Home</Link>
                <Link to="/preventive-care">Preventive Dental Care</Link>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!--================End Home Banner Area =================--> */}
  
 
    <section className="orthodontics-area area-padding-top">
      <Container>
       

        {/* Services Section */}
        <Row className="mt-5">
          {orthodonticServices.map((service, index) => (
            <Col key={index} md={6} lg={4} className="mb-4">
              <Card className="orthodontic-service-card h-100 text-center p-4">
                <div className="service-icon mb-3">
                  <i className={service.icon}></i>
                </div>
                <Card.Body>
                  <Card.Title>{service.title}</Card.Title>
                  <Card.Text>{service.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Benefits Section */}
        <Row className="mt-5">
          <Col lg={6}>
            <div className="benefits-image">
              <img
                src="/images/orthodontics.jpg" // Replace with your image path
                alt="Orthodontics"
                className="img-fluid rounded"
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="benefits-content">
              <h3>Benefits of Orthodontic Treatment</h3>
              <ul className="benefits-list">
                <li>Improved alignment for a healthier bite.</li>
                <li>Enhanced facial aesthetics and smile appearance.</li>
                <li>Easier cleaning leading to better oral hygiene.</li>
                <li>Reduced risk of tooth decay and gum disease.</li>
                <li>Boosted confidence and self-esteem.</li>
              </ul>
              <Link to="/contact" className="learn-more btn btn-primary mt-3">
                Schedule a Consultation
              </Link>
            </div>
          </Col>
        </Row>

        {/* Optional: Testimonials Related to Orthodontics */}
        <Row className="mt-5">
          <Col lg={12}>
            <div className="related-testimonials">
              <h3 className="text-center mb-4">Patient Testimonials</h3>
              <Row>
                {/* Example Testimonial */}
                <Col md={6} lg={4} className="mb-4">
                  <Card className="testimonial-card h-100 text-center p-3">
                    <Card.Body>
                      <img
                        src="/images/testimonials/alex.jpg" // Replace with actual image path
                        alt="Alex Martinez"
                        className="testimonial-photo rounded-circle mb-3"
                      />
                      <Card.Text className="testimonial-feedback">
                        "The team at Dr. Shukla Dental Clinic made my orthodontic treatment comfortable and effective. I love my new smile!"
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="bg-transparent border-top-0">
                      <Card.Title className="testimonial-name mb-0">- Alex Martinez</Card.Title>
                    </Card.Footer>
                  </Card>
                </Col>
                {/* Add more testimonials as needed */}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section> </>
  );
};

export default Orthodontics;
