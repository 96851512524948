// src/TermsAndConditions.js
import React, { useState, useEffect } from 'react';
import {
  FaFileContract,
  FaHandshake,
  FaCalendarCheck,
  FaRupeeSign,
  FaUserMd,
  FaNotesMedical,
  FaGavel,
  FaExclamationTriangle,
  FaCamera,
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
} from 'react-icons/fa';

const TermsAndConditions = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Function to check if the viewport is mobile-sized
  const checkMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Define all necessary inline styles as JavaScript objects
  const styles = {
   
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '20px',
    },
    termsHeader: {
      textAlign: 'center',
      padding: isMobile ? '40px 0' : '60px 0',
      backgroundImage:
        'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("https://images.unsplash.com/photo-1629909613654-28e377c37b09?q=80&w=1200")',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      color: 'white',
      marginBottom: '40px',
    },
    headerTitle: {
      fontSize: '2.5em',
      marginBottom: '15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '10px',
    },
    headerSubtitle: {
      fontSize: '1.2em',
      opacity: 0.9,
    },
    termsContent: {
      background: 'white',
      padding: isMobile ? '20px' : '40px',
      borderRadius: '15px',
      boxShadow: '0 2px 15px rgba(0, 0, 0, 0.1)',
      marginBottom: '30px',
    },
    section: {
      marginBottom: '30px',
      paddingBottom: '20px',
      borderBottom: '1px solid #eee',
    },
    sectionLast: {
      borderBottom: 'none',
      marginBottom: 0,
      paddingBottom: 0,
    },
    sectionTitle: {
      color: '#2c3e50',
      marginBottom: '20px',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    bulletList: {
      listStyleType: 'none',
      marginLeft: '20px',
      paddingLeft: '0',
      marginBottom: '15px',
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
      position: 'relative',
      paddingLeft: '25px',
    },
    bulletIcon: {
      color: '#3498db',
      marginRight: '10px',
    },
    importantNotice: {
      background: '#f8f9fa',
      padding: '20px',
      borderLeft: '4px solid #3498db',
      margin: '20px 0',
      borderRadius: '0 5px 5px 0',
    },
    emergencyNotice: {
      background: '#ff6b6b',
      color: 'white',
      padding: '15px',
      borderRadius: '5px',
      margin: '20px 0',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    emergencyIcon: {
      fontSize: '1.5em',
    },
    contactSection: {
      background: 'linear-gradient(135deg, #2c3e50, #3498db)',
      color: 'white',
      padding: '30px',
      borderRadius: '10px',
      textAlign: 'center',
      marginTop: '40px',
    },
    contactTitle: {
      color: 'white',
      marginBottom: '15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '10px',
    },
    contactInfo: {
      display: 'flex',
      justifyContent: 'center',
      gap: '30px',
      flexWrap: 'wrap',
      marginTop: '20px',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'center' : 'flex-start',
    },
    contactItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    lastUpdated: {
      textAlign: 'center',
      marginTop: '30px',
      color: '#666',
      fontStyle: 'italic',
    },
  };

  return (
    <div >
      {/* Terms Header */}
      {/* Banner Section */}
      <section className="banner_area">
        <div className="banner_inner d-flex align-items-center">
          <div className="container">
            <div className="banner_content d-md-flex justify-content-between align-items-center">
              <div className="mb-3 mb-md-0">
                <h2>Terms and Conditions</h2>
                <p>
                  Please read these terms and conditions carefully before using our website.
                </p>
              </div>
              {/* <div className="page_link">
                <Link to="/">Home</Link>
                <Link to="/terms-and-conditions">Terms and Conditions</Link>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* End Banner Section */}

      {/* Terms Content */}
      <div style={styles.container}>
        <div style={styles.termsContent}>
          {/* Acceptance of Terms Section */}
          <div style={styles.section}>
            <h2 style={styles.sectionTitle}>
              <FaHandshake /> Acceptance of Terms
            </h2>
            <p>
              By scheduling an appointment or receiving treatment at Dr. Shukla Dental Clinic, you agree
              to be bound by these terms and conditions. Please read them carefully before proceeding
              with any treatment.
            </p>
          </div>

          {/* Appointments and Cancellations Section */}
          <div style={styles.section}>
            <h2 style={styles.sectionTitle}>
              <FaCalendarCheck /> Appointments and Cancellations
            </h2>
            <ul style={styles.bulletList}>
              <li style={styles.listItem}>
                {/* Optional: Add an icon for bullet */}
                {/* <FaChevronRight style={styles.bulletIcon} /> */}
                Appointments must be scheduled in advance
              </li>
              <li style={styles.listItem}>
                24-hour notice is required for cancellations
              </li>
              <li style={styles.listItem}>
                Late arrivals may need to be rescheduled
              </li>
              <li style={styles.listItem}>
                Repeated no-shows may result in scheduling restrictions
              </li>
            </ul>
            <div style={styles.importantNotice}>
              <strong>Note:</strong> A cancellation fee may apply for appointments cancelled with less
              than 24 hours notice.
            </div>
          </div>

          {/* Payment Terms Section */}
          <div style={styles.section}>
            <h2 style={styles.sectionTitle}>
              <FaRupeeSign /> Payment Terms
            </h2>
            <ul style={styles.bulletList}>
              <li style={styles.listItem}>
                Payment is expected at the time of service
              </li>
              <li style={styles.listItem}>
                We accept cash, major credit cards, and select insurance plans
              </li>
              <li style={styles.listItem}>
                Payment plans may be available for extensive treatments
              </li>
              <li style={styles.listItem}>
                Insurance claims are filed as a courtesy to our patients
              </li>
              <li style={styles.listItem}>
                Patients are responsible for any amounts not covered by insurance
              </li>
            </ul>
          </div>

          {/* Treatment and Services Section */}
          <div style={styles.section}>
            <h2 style={styles.sectionTitle}>
              <FaUserMd /> Treatment and Services
            </h2>
            <ul style={styles.bulletList}>
              <li style={styles.listItem}>
                All treatments will be discussed and agreed upon before proceeding
              </li>
              <li style={styles.listItem}>
                Treatment plans may be modified based on clinical findings
              </li>
              <li style={styles.listItem}>
                Patients have the right to refuse treatment
              </li>
              <li style={styles.listItem}>
                We reserve the right to refuse treatment in certain circumstances
              </li>
              <li style={styles.listItem}>
                Follow-up care instructions must be followed for optimal results
              </li>
            </ul>
          </div>

          {/* Medical Information Section */}
          <div style={styles.section}>
            <h2 style={styles.sectionTitle}>
              <FaNotesMedical /> Medical Information
            </h2>
            <ul style={styles.bulletList}>
              <li style={styles.listItem}>
                Accurate medical history must be provided
              </li>
              <li style={styles.listItem}>
                Updates to medical history must be reported promptly
              </li>
              <li style={styles.listItem}>
                Failure to disclose medical information may affect treatment
              </li>
              <li style={styles.listItem}>
                We maintain strict patient confidentiality
              </li>
            </ul>
          </div>

          {/* Emergency Notice */}
          <div style={styles.emergencyNotice}>
            <FaExclamationTriangle style={styles.emergencyIcon} />
            <div>
              <strong>Emergency Care:</strong> For after-hours emergencies, please call our emergency
              contact number. Not all dental conditions qualify as emergencies.
            </div>
          </div>

          {/* Photography and Records Section */}
          <div style={styles.section}>
            <h2 style={styles.sectionTitle}>
              <FaCamera /> Photography and Records
            </h2>
            <ul style={styles.bulletList}>
              <li style={styles.listItem}>
                Clinical photographs may be taken for treatment planning
              </li>
              <li style={styles.listItem}>
                Records may be used for educational purposes with consent
              </li>
              <li style={styles.listItem}>
                Patient privacy is maintained in accordance with regulations
              </li>
            </ul>
          </div>

          {/* Legal Considerations Section */}
          <div style={styles.section}>
            <h2 style={styles.sectionTitle}>
              <FaGavel /> Legal Considerations
            </h2>
            <ul style={styles.bulletList}>
              <li style={styles.listItem}>
                The clinic is not liable for treatment complications if post-care instructions are
                not followed
              </li>
              <li style={styles.listItem}>
                Disputes will be resolved through mediation when possible
              </li>
              <li style={styles.listItem}>
                These terms are governed by Indian law
              </li>
            </ul>
          </div>

          {/* Contact Section */}
          <div style={styles.contactSection}>
            <h2 style={styles.contactTitle}>
              <FaEnvelope /> Contact Information
            </h2>
            <div style={styles.contactInfo}>
              <div style={styles.contactItem}>
                <FaPhone />
                <span>+91 90965 69324</span>
              </div>
              <div style={styles.contactItem}>
                <FaMapMarkerAlt />
                <span>Gondia, Maharashtra</span>
              </div>
            </div>
          </div>
        </div>

        {/* Last Updated */}
        <div style={styles.lastUpdated}>Last updated: January 4, 2025</div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
