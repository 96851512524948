import React from "react";
import { Link } from "react-router-dom";
import doctor from "../assets/doctor.png";import { BsTelephoneFill, BsPhone  } from "react-icons/bs";
// import {
//   FaSmile,
//   FaCalendarCheck,
//   FaAward,
//   FaProcedures,
// } from "react-icons/fa";
import {
  FaUserMd,
  FaSmile,
  FaCalendarCheck,
  FaAward,
  FaProcedures,
  FaGraduationCap,
  FaCertificate,
  FaTeeth,
  FaTooth,
  FaSmileBeam,
  FaTeethOpen,
  FaBaby,
  FaBone,
  FaMicroscope,
  FaStarOfLife,
  FaHeart,
} from "react-icons/fa";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./About.css";

const About = () => {
  const stats = [
    {
      icon: <FaSmile className="text-primary" />,
      number: "5000+",
      label: "Happy Patients",
    },
    {
      icon: <FaCalendarCheck className="text-primary" />,
      number: "13+",
      label: "Years Experience",
    },
    {
      icon: <FaAward className="text-primary" />,
      number: "6+",
      label: "Certifications",
    },
    {
      icon: <FaProcedures className="text-primary" />,
      number: "1000+",
      label: "Successful Treatments",
    },
  ];

  const services = [
    {
      icon: <i className="fas fa-teeth text-primary" />,
      title: "Immediate Dental Implants",
      description:
        "While most clinics require 3-6 months waiting period after implantation for teeth fitting, at our clinic, we provide immediate teeth placement after implant surgery.",
    },
    {
      icon: <i className="fas fa-tooth text-primary" />,
      title: "Occlusion Correction",
      description:
        "Did you know that when teeth are worn down or when 2 or more teeth are removed from upper or lower jaw, the meeting point of upper and lower jaw teeth (called Occlusion).",
    },
    {
      icon: <i className="fas fa-smile-beam text-primary" />,
      title: "Digital Smile Design",
      description:
        "Advanced digital technology to design your perfect smile before treatment.",
    },
    {
      icon: <i className="fas fa-clock text-primary" />,
      title: "Single Sitting Root Canal Treatment",
      description:
        "Efficient and comfortable root canal treatment completed in a single session.",
    },
    {
      icon: <i className="fas fa-teeth-open text-primary" />,
      title: "Orthodontic Treatment",
      description:
        "Treatment for straightening crooked and misaligned teeth with advanced techniques.",
    },
    {
      icon: <i className="fas fa-smile text-primary" />,
      title: "Dental Aligners",
      description:
        "Invisible aligners for teeth straightening without traditional braces.",
    },
    {
      icon: <i className="fas fa-star text-primary" />,
      title: "Beautiful Smile Without Braces",
      description:
        "Achieve your perfect smile through alternative treatment methods.",
    },
    {
      icon: <i className="fas fa-tooth text-primary" />,
      title: "Dental Implants",
      description:
        "State-of-the-art dental implant solutions for missing teeth.",
    },
    {
      icon: <i className="fas fa-teeth text-primary" />,
      title: "Fixed Full Dentures with Implants",
      description:
        "Complete dental restoration using implant-supported fixed dentures.",
    },
    {
      icon: <i className="fas fa-baby text-primary" />,
      title: "Comprehensive Pediatric Dental Care",
      description:
        "Complete dental treatment for children with gentle care approach.",
    },
    {
      icon: <i className="fas fa-bone text-primary" />,
      title: "Jaw Fracture Treatment",
      description: "Specialized treatment for broken jaw and related injuries.",
    },
    {
      icon: <i className="fas fa-magic text-primary" />,
      title: "Scar Revision",
      description:
        "Advanced treatments to improve the appearance of dental and facial scars.",
    },
    {
      icon: <i className="fas fa-gem text-primary" />,
      title: "Dental Jewelry",
      description:
        "Aesthetic dental jewelry services for cosmetic enhancement.",
    },
    {
      icon: <i className="fas fa-compress-arrows-alt text-primary" />,
      title: "Minimal Invasive Gap Treatment",
      description:
        "Minimally invasive techniques to reduce gaps between teeth.",
    },
  ];

  const additionalServices = [
    {
      icon: <i className="fas fa-smile-beam text-primary" />,
      title: "Dimple Creation",
      description: "Cosmetic procedures for creating artificial dimples.",
    },

    {
      icon: <i className="fas fa-gem text-primary" />,
      title: "Laser Mole Removal",
      description: "Safe and effective laser treatment for mole removal.",
    },

    {
      icon: <i className="fas fa-x-ray text-primary" />,
      title: "Digital Dental X-Ray",
      description: "Advanced digital X-ray imaging for precise diagnosis.",
    },

    {
      icon: <i className="fas fa-search text-primary" />,
      title: "Oral Cancer Screenin",
      description: "Comprehensive oral cancer screening and treatment.",
    },

    {
      icon: <i className="fas fa-fill text-primary" />,
      title: "Composite Filling",
      description: "Natural-looking tooth-colored composite fillings.",
    },

    {
      icon: <i className="fas fa-compress-arrows-alt text-primary" />,
      title: "Ultrasonic Teeth Cleaning",
      description: "Professional teeth cleaning using ultrasonic technology.",
    },
    {
      icon: <i className="fas fa-teeth text-primary" />,
      title: "Fixed Artificial Teeth",
      description: "Permanent solution for missing teeth with fixed prosthetic",
    },
  ];

  return (
    <div>
      {/* Header Section */}
      <section className="banner_area">
        <div className="banner_inner d-flex align-items-center">
          <div className="container">
            <div className="banner_content d-md-flex justify-content-between align-items-center">
              <div className="mb-3 mb-md-0">
                <h2>About</h2>
                <p>Your Trusted Dental Clinic In Gondia</p>
              </div>
              <div className="page_link">
                <Link to="/">Home</Link>
                <Link to="/about-us"> About </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container my-5">
        {/* Doctor Profile Section */}
        <div className="row doctor-profile align-items-center bg-light p-4 rounded mb-5">
          <div className="col-md-4 text-center">
            <div className="doctor-image mx-auto position-relative">
              {/* Blue Circular Background */}
              <div
                className="blue-circle position-absolute"
                style={{
                  width: "150",
                  height: "150",
                  backgroundColor: "blue",
                  borderRadius: "50%",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                }}
              ></div>

              {/* Doctor Image */}
              <img
                src={doctor}
                alt="Dr. Rahul R. Shukla"
                className="img-fluid rounded-circle"
                style={{
                  position: "relative",
                  zIndex: 2,
                }}
              />
            </div>
          </div>

          <div className="col-md-8">
            <h2>
              <FaUserMd className="text-primary" /> Dr. Rahul R. Shukla, B.D.S.
            </h2>

            <p>
              Serving you since 2010 at Shukla Dental Clinic Implant Centre,
              Aligner & Braces Clinic. Get your desired smile in just 24 hours.
            </p>
          </div>
        </div>

        {/* Stats Section */}
        <div className="container py-5">
          <div className="row text-center stats-container">
            {stats.map((stat, index) => (
              <div
                key={index}
                className="col-md-3 col-sm-6 stat-card mb-4"
                style={{
                  padding: "20px",
                  borderRadius: "10px",
                  backgroundColor: "#fff",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.3s, box-shadow 0.3s",
                }}
              >
                <div
                  className="mb-3"
                  style={{
                    fontSize: "2.5rem",
                    color: "#007bff", // Blue color for the icons
                  }}
                >
                  {stat.icon}
                </div>
                <div
                  className="stat-number mb-1"
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    color: "#333",
                  }}
                >
                  {stat.number}
                </div>
                <div
                  className="stat-label"
                  style={{
                    fontSize: "1rem",
                    color: "#666",
                  }}
                >
                  {stat.label}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Qualifications Section */}
        <div className="qualifications bg-light p-4 rounded mb-5">
          <h3>
            <FaGraduationCap /> Educational Qualifications and Certifications
          </h3>
          <ul className="list-unstyled">
            {[
              "B.D.S. (Government Dental College, Nagpur)",
              "Certification in Basic & Advanced Implantology, Mumbai",
              "Certification in basic and advanced implant prosthdontics, Mumbai",
              "Certification in painless dentistry",
              "Certification in Endodontics, Nagpur",
              "Certification in Orthodontics, Nagpur",
            ].map((item, index) => (
              <li key={index} className="mb-2">
                <FaCertificate className="text-primary me-2" /> {item}
              </li>
            ))}
          </ul>
        </div>

        {/* Services Section */}

        <section className="service-area">
          <Container>
            <Row className="mt-5">
              {services.map((service, index) => (
                <Col key={index} md={6} lg={4} className="mb-4">
                  <Card
                    className="service-card h-100 text-center p-3 hover-effect"
                    style={{
                      border: "1px solid black", // Added black border here
                    }}
                  >
                    <Card.Body>
                      <div className="mb-3" style={{ fontSize: "2rem" }}>
                        {service.icon}
                      </div>
                      <h3 className="mb-3">{service.title}</h3>
                      <p>{service.description}</p>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>

            {/* Updated Additional Services Section */}
            <div className="additional-services-header text-center my-5">
              <i
                className="fas fa-plus-circle"
                style={{
                  fontSize: "3rem",
                  color: "#007bff", // Attractive blue color for the icon
                  marginBottom: "10px",
                }}
              ></i>
              <h2
                style={{
                  fontWeight: "bold",
                  fontSize: "2.5rem",
                  color: "#333",
                }}
              >
                Additional Services
              </h2>
              <p
                style={{
                  fontSize: "1.1rem",
                  color: "#555",
                  maxWidth: "600px",
                  margin: "0 auto",
                }}
              >
                Explore our range of specialized services designed to enhance
                your oral health and smile aesthetics.
              </p>
            </div>

            {/* Render Additional Services */}
            <Row className="mt-5">
              {additionalServices.map((service, index) => (
                <Col key={index} md={6} lg={4} className="mb-4">
                  <Card
                    className="service-card h-100 text-center p-3 hover-effect"
                    style={{
                      border: "1px solid black", // Added black border here
                      boxShadow: "none", // Optional: removes any default shadow to keep it clean with only the border
                    }}
                  >
                    <Card.Body>
                      <div
                        className="mb-3"
                        style={{ fontSize: "2.5rem", color: "#ff5722" }}
                      >
                        {service.icon}
                      </div>
                      <h3
                        className="mb-3"
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.5rem",
                          color: "#333",
                        }}
                      >
                        {service.title}
                      </h3>
                      <p style={{ color: "#666" }}>{service.description}</p>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </section>

        <div
          className="contact-info text-center py-5"
          style={{
            background:
              "url('/img/Services/Tooth-Extraction-cosmetic-bonding-Smile-Designing-Root-Canal-Treatment-drshukladentalclinic-in-gondia.jpg') no-repeat center center/cover",
            color: "white",
            borderRadius: "8px",
            position: "relative",
          }}
        >
          {/* Overlay for better text visibility */}
          <div
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent overlay
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: "8px",
              zIndex: 1,
            }}
          ></div>

          {/* Text content */}
          <div style={{ position: "relative", zIndex: 2 }}>
            <h3 style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)" }}>
              <BsTelephoneFill className="me-2" /> For Appointments
            </h3>
            <p style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)" }}>
              <BsPhone className="me-2" /> +91 8263927680 | +91 8806304125
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
