// src/PrivacyPolicy.js
import React, { useState, useEffect } from 'react';
import {
  FaShieldAlt,
  FaInfoCircle,
  FaDatabase,
  FaUserShield,
  FaLock,
  FaShareAlt,
  FaUserClock,
  FaUserEdit,
  FaCookieBite,
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
  FaChevronRight,
} from 'react-icons/fa';

const PrivacyPolicy = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Function to check if the viewport is mobile-sized
  const checkMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Define all necessary inline styles as JavaScript objects
  const styles = {
   
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '20px',
    },
    privacyHeader: {
      textAlign: 'center',
      padding: isMobile ? '40px 0' : '60px 0',
      backgroundImage:
        'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("https://images.unsplash.com/photo-1629909615184-74f495363b67?q=80&w=1200")',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      color: 'white',
      marginBottom: '40px',
    },
    headerTitle: {
      fontSize: '2.5em',
      marginBottom: '15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '10px',
    },
    headerSubtitle: {
      fontSize: '1.2em',
      opacity: 0.9,
    },
    privacyContent: {
      background: 'white',
      padding: isMobile ? '20px' : '40px',
      borderRadius: '15px',
      boxShadow: '0 2px 15px rgba(0, 0, 0, 0.1)',
      marginBottom: '30px',
    },
    section: {
      marginBottom: '30px',
      paddingBottom: '20px',
      borderBottom: '1px solid #eee',
    },
    sectionLast: {
      borderBottom: 'none',
      marginBottom: 0,
      paddingBottom: 0,
    },
    sectionTitle: {
      color: '#2c3e50',
      marginBottom: '20px',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    bulletList: {
      listStyleType: 'none',
      marginLeft: '20px',
      paddingLeft: '0',
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
    },
    bulletIcon: {
      color: '#3498db',
      marginRight: '10px',
    },
    contactSection: {
      background: 'linear-gradient(135deg, #2c3e50, #3498db)',
      color: 'white',
      padding: '30px',
      borderRadius: '10px',
      textAlign: 'center',
      marginTop: '40px',
    },
    contactTitle: {
      color: 'white',
      marginBottom: '15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '10px',
    },
    contactInfo: {
      display: 'flex',
      justifyContent: 'center',
      gap: '30px',
      flexWrap: 'wrap',
      marginTop: '20px',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'center' : 'flex-start',
    },
    contactItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    lastUpdated: {
      textAlign: 'center',
      marginTop: '30px',
      color: '#666',
      fontStyle: 'italic',
    },
  };

  return (
    <div style={styles.body}>
            {/* Banner Section */}
            <section className="banner_area">
        <div className="banner_inner d-flex align-items-center">
          <div className="container">
            <div className="banner_content d-md-flex justify-content-between align-items-center">
              <div className="mb-3 mb-md-0">
                <h2>Privacy Policy</h2>
                <p>
                Your privacy is important to us. Please read our privacy policy carefully to understand how we collect, use, and protect your personal information.                </p>
              </div>
              {/* <div className="page_link">
                <Link to="/">Home</Link>
                <Link to="/terms-and-conditions">Terms and Conditions</Link>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* End Banner Section */}
     
      <div style={styles.container}>
        <div style={styles.privacyContent}>
          {/* Introduction Section */}
          <div style={styles.section}>
            <h2 style={styles.sectionTitle}>
              <FaInfoCircle /> Introduction
            </h2>
            <p>
              At Dr. Shukla Dental Clinic, we are committed to protecting your privacy and maintaining
              the confidentiality of your personal and medical information. This privacy policy
              explains how we collect, use, disclose, and safeguard your information when you visit our
              clinic or use our services.
            </p>
          </div>

          {/* Information We Collect Section */}
          <div style={styles.section}>
            <h2 style={styles.sectionTitle}>
              <FaDatabase /> Information We Collect
            </h2>
            <ul style={styles.bulletList}>
              <li style={styles.listItem}>
                <FaChevronRight style={styles.bulletIcon} />
                Personal identification information (Name, address, contact details)
              </li>
              <li style={styles.listItem}>
                <FaChevronRight style={styles.bulletIcon} />
                Medical and dental history
              </li>
              <li style={styles.listItem}>
                <FaChevronRight style={styles.bulletIcon} />
                Insurance information (if applicable)
              </li>
              <li style={styles.listItem}>
                <FaChevronRight style={styles.bulletIcon} />
                Treatment records and dental images
              </li>
              <li style={styles.listItem}>
                <FaChevronRight style={styles.bulletIcon} />
                Payment information
              </li>
              <li style={styles.listItem}>
                <FaChevronRight style={styles.bulletIcon} />
                Appointment history
              </li>
            </ul>
          </div>

          {/* How We Use Your Information Section */}
          <div style={styles.section}>
            <h2 style={styles.sectionTitle}>
              <FaUserShield /> How We Use Your Information
            </h2>
            <ul style={styles.bulletList}>
              <li style={styles.listItem}>
                <FaChevronRight style={styles.bulletIcon} />
                Provide dental care and treatment
              </li>
              <li style={styles.listItem}>
                <FaChevronRight style={styles.bulletIcon} />
                Communicate about appointments and follow-ups
              </li>
              <li style={styles.listItem}>
                <FaChevronRight style={styles.bulletIcon} />
                Process payments and insurance claims
              </li>
              <li style={styles.listItem}>
                <FaChevronRight style={styles.bulletIcon} />
                Maintain accurate medical records
              </li>
              <li style={styles.listItem}>
                <FaChevronRight style={styles.bulletIcon} />
                Improve our services and patient care
              </li>
              <li style={styles.listItem}>
                <FaChevronRight style={styles.bulletIcon} />
                Comply with legal and regulatory requirements
              </li>
            </ul>
          </div>

          {/* Data Protection Section */}
          <div style={styles.section}>
            <h2 style={styles.sectionTitle}>
              <FaLock /> Data Protection
            </h2>
            <p>
              We implement appropriate security measures to protect your personal information from
              unauthorized access, disclosure, alteration, or destruction. Our staff is trained in
              maintaining patient confidentiality and follows strict protocols for handling sensitive
              information.
            </p>
          </div>

          {/* Information Sharing Section */}
          <div style={styles.section}>
            <h2 style={styles.sectionTitle}>
              <FaShareAlt /> Information Sharing
            </h2>
            <p>We may share your information with:</p>
            <ul style={styles.bulletList}>
              <li style={styles.listItem}>
                <FaChevronRight style={styles.bulletIcon} />
                Other healthcare providers involved in your treatment
              </li>
              <li style={styles.listItem}>
                <FaChevronRight style={styles.bulletIcon} />
                Insurance companies for claim processing
              </li>
              <li style={styles.listItem}>
                <FaChevronRight style={styles.bulletIcon} />
                Legal authorities when required by law
              </li>
              <li style={styles.listItem}>
                <FaChevronRight style={styles.bulletIcon} />
                Third-party service providers who assist in our operations
              </li>
            </ul>
          </div>

          {/* Retention Period Section */}
          <div style={styles.section}>
            <h2 style={styles.sectionTitle}>
              <FaUserClock /> Retention Period
            </h2>
            <p>
              We retain patient records for the period required by law and professional guidelines.
              After this period, records are securely destroyed or anonymized.
            </p>
          </div>

          {/* Your Rights Section */}
          <div style={styles.section}>
            <h2 style={styles.sectionTitle}>
              <FaUserEdit /> Your Rights
            </h2>
            <ul style={styles.bulletList}>
              <li style={styles.listItem}>
                <FaChevronRight style={styles.bulletIcon} />
                Access your personal information
              </li>
              <li style={styles.listItem}>
                <FaChevronRight style={styles.bulletIcon} />
                Request corrections to your information
              </li>
              <li style={styles.listItem}>
                <FaChevronRight style={styles.bulletIcon} />
                Receive a copy of your dental records
              </li>
              <li style={styles.listItem}>
                <FaChevronRight style={styles.bulletIcon} />
                Choose how we communicate with you
              </li>
              <li style={styles.listItem}>
                <FaChevronRight style={styles.bulletIcon} />
                File a complaint about privacy concerns
              </li>
            </ul>
          </div>

          {/* Website and Cookies Section */}
          <div style={styles.section}>
            <h2 style={styles.sectionTitle}>
              <FaCookieBite /> Website and Cookies
            </h2>
            <p>
              Our website may use cookies and similar technologies to enhance your browsing
              experience. These technologies collect anonymous data about website usage and do not
              contain personal information.
            </p>
          </div>

          {/* Contact Section */}
          <div style={styles.contactSection}>
            <h2 style={styles.contactTitle}>
              <FaEnvelope /> Contact Us - About Privacy
            </h2>
            <div style={styles.contactInfo}>
              <div style={styles.contactItem}>
                <FaPhone />
                <span>+91 90965 69324</span>
              </div>
              <div style={styles.contactItem}>
                <FaMapMarkerAlt />
                <span>Gondia, Maharashtra</span>
              </div>
            </div>
          </div>
        </div>

        {/* Last Updated */}
        <div style={styles.lastUpdated}>Last updated: January 4, 2025</div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
